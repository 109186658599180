import posthog from 'posthog-js'

export default {
    install(app) {
      app.provide('posthog', posthog.init(
        // import.meta.env.VITE_POSTHOG_KEY,
        "phc_3rAd3V8KHnsL3j6wFoyKBFLCDxqU2k7VSOlilR1NhgZ",
        {
          api_host: 'https://app.posthog.com',
        }
      ))
    },
};

