import pinia from '@/stores'
import posthogPlugin from './posthog'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '../router'
import {i18n} from './locale'
import { initSentry } from './sentry'
import unhead from './unhead';
import { VueReCaptcha } from 'vue-recaptcha-v3'



export function registerPlugins (app) {
  // Manage Accept-Language header directly in component using user locale state // localstorage
  axios.defaults.headers.common = {'Content-Type': "application/json"}
  axios.defaults.headers.common = {'Accept': "application/json"}
  axios.defaults.withCredentials = true;
  axios.defaults.withXSRFToken = true;


    app
      .use(VueReCaptcha, { siteKey: '6LdxGoUpAAAAAAU6YY9qzhlTREXpbnbTQLbWHhyx' })
    // store management
      .use(pinia)
    // product analytics plugin
      .use(posthogPlugin)
    // vue router
      .use(router)
    // axios and Vue axios wrappers to interact with REST API
      .use(VueAxios, axios)
    // localization
      .use(i18n)
    // Seo/Meta tools 
    // https://unhead.unjs.io/setup/vue/installation Docs, Usage Example
      .use(unhead)

    //logging
    initSentry(app, router)

}