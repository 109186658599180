import { createRouter, createWebHistory } from 'vue-router'
import posthog from 'posthog-js'
const routes = [
  { 
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'homepage',
        component: () => import('@/views/main/Home.vue'),
      },

    //   static pages paths
    //   footer first column
    //   {
    //     path: '/about',
    //     name: 'about',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/contacts',
    //     name: 'contacts',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/privacy',
    //     name: 'privacy',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/news',
    //     name: 'news',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   second column
    //   {
    //     path: '/howto',
    //     name: 'howto',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/mobile',
    //     name: 'mobile',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/calc',
    //     name: 'calc',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/local-services',
    //     name: 'services',
    //     component: () => import('@/views/static/Static.vue')
    //   },
    //   {
    //     path: '/faq',
    //     name: 'faq',
    //     component: () => import('@/views/static/Static.vue')
    //   },
      // third column
      // {
      //   path: '/howto-pros',
      //   name: 'howto-pros',
      //   component: () => import('@/views/static/Static.vue')
      // },
      // {
      //   path: '/login',
      //   name: 'login',
      //   component: () => import('@/views/static/Static.vue')
      // },
      // {
      //   path: '/mobile-pro',
      //   name: 'mobile-pro',
      //   component: () => import('@/views/static/Static.vue')
      // },
      // {
      //   path: '/success',
      //   name: 'success',
      //   component: () => import('@/views/static/Static.vue')
      // },
      // fourth column
      // {
      //   path: '/support',
      //   name: 'support',
      //   component: () => import('@/views/static/Static.vue')
      // },
      // {
      //   path: '/terms',
      //   name: 'terms',
      //   component: () => import('@/views/static/Static.vue')
      // },
      // {
      //   path: '/privacy',
      //   name: 'privacy',
      //   component: () => import('@/views/static/Static.vue')
      // },
      // {
      //   path: '/place-ad',
      //   name: 'place-ad',
      //   component: () => import('@/views/static/Static.vue')
      // },
    ]
  },

  // {
  //   // catalog page. With url encoded search query and filters.
  //   path: '/catalog',
  //   component: () => import('@/layouts/Catalog.vue'),
  //   children: [
  //     {
  //       path: ':searchQuery?/:filterQuery?',
  //       name: 'catalog',
  //       component: () => import('@/views/catalog/Catalog.vue'),
  //     },
  //   ]
  // },

  // {
  //   path:'/product/:productName',
  //   component: () => import('@/layouts/Catalog.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'product',
  //       component: () => import('@/views/catalog/Product.vue'),
  //     }
  //   ]
  // },

  {
    /**blank page layout*/
    path: '/',
    component: () => import('@/layouts/Blank.vue'),
    children: [
      // {
      //   path: '/test',
      //   name: 'test',
      //   component: () => import ('@/views/blank/Test.vue')
      // },
      // {
      //   path: '/login',
      //   name: 'login',
      //   component: () => import('@/views/blank/Login.vue')
      // },
      // {
      //   path: '/register',
      //   name: 'register',
      //   component: () => import('@/views/blank/Register.vue')
      // },
      // {
      //   path: '/confirm',
      //   name: 'confirm',
      //   component: () => import('@/views/blank/Confirm.vue')
      // },
      // {
      //   path: '/reset',
      //   name: 'reset',
      //   component: () => import('@/views/blank/Reset.vue')
      // },
      // {
      //   path: '/renew',
      //   name: 'renew',
      //   component: () => import('@/views/blank/NewPassword.vue')
      // },
      {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/404.vue')
      },
    ]
  },
]


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  posthog.capture("$pageview", {
    $current_url: to.fullPath
  })  
})

export default router
